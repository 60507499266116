
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .winner-left {
    
    -webkit-clip-path: polygon(0 0, 60% 0, 40% 100%, 0 100%);
    clip-path: polygon(0 0, 60% 0, 40% 100%, 0 100%);
  }

  .winner-right {
    position: absolute;

    -webkit-clip-path: polygon(60% 0, 100% 0%, 100% 100%, 40% 100%);
    clip-path: polygon(60% 0, 100% 0%, 100% 100%, 40% 100%);
  }

  .sidebar-hr {
    @apply bg-gray-200 dark:bg-gray-800
    border border-gray-200 dark:border-gray-800 rounded-full mx-2;
  }

  .navbar-left, navbar-right {
    display: flex;
    align-items: center;
  }

  .facebook-icon {
    background-color: #1877f2;
  }

  .instagram-icon {
    background-color: #c13584;
  }

  .line {
    height: 1px;
    background: black;
  }

  .player-stats {
    @apply absolute w-auto p-2 m-2 min-w-max rounded-md shadow-md
    text-white bg-gray-900 text-xs font-bold transition-all duration-100
    mb-20 scale-0 origin-left;
  }

  .no-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }

  .no-scroll::-webkit-scrollbar {
    display:none !important;
  }
}

@font-face {
  font-family: 'Phosphate';
  src: url(./fonts/PhosphateRR\ Solid.ttf) format('truetype');
}

@font-face {
  font-family: 'KC Leisure Park Bold';
  src: url(./fonts/KCLeisurePark-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'KC Leisure Park Thin';
  src: url(./fonts/KCLeisurePark-Thin.otf) format('truetype');
}

@font-face {
  font-family: 'OpenSans Condensed Reg';
  src: url(./fonts/OpenSans_Condensed-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans Condensed Bold';
  src: url(./fonts/OpenSans_Condensed-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans Condensed Medium';
  src: url(./fonts/OpenSans_Condensed-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans Reg';
  src: url(./fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans Bold';
  src: url(./fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans Medium';
  src: url(./fonts/OpenSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans SemiCondensed Reg';
  src: url(./fonts/OpenSans_SemiCondensed-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans SemiCondensed Bold';
  src: url(./fonts/OpenSans_SemiCondensed-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans SemiCondensed Medium';
  src: url(./fonts/OpenSans_SemiCondensed-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Utendo Regular';
  src: url(./fonts/Utendo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Utendo Bold';
  src: url(./fonts/Utendo-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Utendo Semibold';
  src: url(./fonts/Utendo-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'abcgintonord-med';
  src: url(./fonts/ABCGintoNord-Medium-Trial-BF651b7b7839fc0.otf) format('truetype');
}

@font-face {
  font-family: 'heavitas';
  src: url(./fonts/Heavitas.ttf) format('truetype');
}

@font-face {
  font-family: 'Lemon Milk Regular';
  src: url(./fonts/LEMONMILK-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Lemon Milk Bold';
  src: url(./fonts/LEMONMILK-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Lemon Milk Medium';
  src: url(./fonts/LEMONMILK-Medium.otf) format('truetype');
}


@layer utilities {
  .toggle-btn {
    @apply [&>div::before]:translate-y-0 [&>div::before]:rotate-45 [&>div::after]:translate-y-0 [&>div::after]:-rotate-45 [&>div]:bg-transparent
    [&>div]:rotate-[720deg]
  }
}